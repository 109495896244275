import _objectSpread from "/home/forge/app.superlantis.com/releases/20240718211311/node_modules/.pnpm/@babel+runtime@7.21.0/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapGetters } from 'vuex';
export default {
  name: 'Error',
  data: function data() {
    return {
      locationPath: '/login',
      textBtn: this.$t('global.back')
    };
  },
  computed: _objectSpread({}, mapGetters({
    isAuthed: 'auth/isAuthed'
  })),
  mounted: function mounted() {
    if (this.isAuthed) {
      this.locationPath = this.$router.options.history.state.back || '/home';
    } else {
      this.locationPath = '/login';
    }
  },
  methods: {}
};